<template>
  <Stack
    class="w-full specifications-container shadow-xs"
    justify="between"
    align="center"
    @click="setSpecificationsTray"
    data-fs="specificationsButton"
  >
    <Heading size="xxs">Specifications</Heading>
    <Icon name="chevron-right" :size="16" />
  </Stack>
</template>

<script lang="ts" setup>
const { setSpecificationsTray } = useUIState();
</script>

<style>
/** This should obviously be done with tailwind, but I couldnt get it to work using the class **/
.specifications-container {
  padding: 16px;
  background-color: white;
  border-radius: 4px;
  cursor: pointer;
}
.specifications-container:hover {
  outline: 1px solid #1e55c0;
}
</style>
